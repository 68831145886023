<template>
    <div class="max-w-screen-mobile mx-auto h-screen py-2">
        <Header :language="lang" 
            @langchanged="langchanged_">
        </Header>
        <div class="mx-6 text-center">
            <h4 class="font-heading text-h4 text-sepiaBlack dark:text-white pt-24">{{ $t('login.message', {input: name}) }}</h4>
            <p class="font-body text-bodyM text-redDamask dark:text-redDamask pt-8">{{ $t('login.successMessage') }}</p>
            <p class="font-body text-bodyM text-sepiaBlack dark:text-white pt-8 pb-16">{{ $t('login.successText') }}</p>
            <router-link :to="{ name: 'MobileMenu' }" >
                <Button :text="$t('login.skipBtn')" buttonType="secondary" fullWidth class="mb-2" />
            </router-link>
            <router-link :to="{ name: 'Onboarding1' }">
                <Button :text="$t('login.startBtn')" buttonType="primary" fullWidth  />
            </router-link>
        </div>
    </div>
</template>

<script>

import Header from "@/components/mobile/Header.vue";
import Button from '@/components/Button.vue';

export default {
    name: "ThankYou",
    components: {
        Header,
        Button
    },
    mounted() {
        this.getPin();
    },
    data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
            email: '',
            pin: '',
            name: window.localStorage.name
        }
    },
    methods: {
        getPin() {
            const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null
            this.axios.get(this.baseURL + `/wp-json/wp/v2/pin`, {params: {user_id: authUser.data.user.id}}, {}).then(response => {
                const response_data = response.data;
                this.pin = response_data;
            }).catch( (error) => {
                console.log(error);
            });
        },
        
        langchanged_(value) {
            this.lang = value;
        }
    },
};
</script>
